import { AccountBidSchema, AccountConnectRequestSchema, CreateBidResponse, CreateBidSchema, AccountInfoSchema, UtxoSchema, AccountInfo, Utxo, EmailRegistrationSchema } from "@/schemas/api";
import { useAccountStore } from "@/stores/accountStore";
import { useMutation, useQuery, UseQueryOptions } from "@tanstack/react-query";
import { clientFetchAndValidate } from "@/lib/clientFetchAndValidate";
import { RankedBid } from '@/schemas/auction';
import { makeNetworkAddressSchema, Network, Wallet } from '@/schemas/bitcoin';
import { KitDate } from '@/schemas/kit';
import { AddressPurpose, GetAccountsResult, request as walletRequest } from 'sats-connect';
import { } from '@leather.io/rpc';
import { toast } from 'sonner';
import { useCurrentAuctionQuery } from './auction';
import { agoraUrl } from "@/lib/agoraUrl";
import { base64ToHex, hexToBase64 } from "@/lib/convertHexBase64";
import { useJwtStore } from "@/stores/jwtStore";
import { JwtResponseSchema } from "@/schemas/jwt";

type RefreshAuthResponse = {
    ordinalAddress: string;
    btcAddress: string;
    btcAddressPubKey: string;
    network: Network;
    wallet: Wallet;
};

export const useRefreshAuthMutation = () => {
    const setWalletInfo = useAccountStore(state => state.setWalletInfo);
    const clearWalletInfo = useAccountStore(state => state.clearWalletInfo);
    const setIsConnected = useAccountStore(state => state.setIsConnected);

    return useMutation({
        mutationFn: async (network: Network | '') => {
            const response = await fetch('/api/account/refresh', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
            });

            if (!response.ok) {
                throw new Error('Failed to refresh authentication');
            }
            const {
                ordinalAddress,
                btcAddress,
                btcAddressPubKey,
                wallet
            } = await response.json() as RefreshAuthResponse;

            return { ordinalAddress, btcAddress, btcAddressPubKey, wallet, network };

        },
        onSuccess: (data) => {
            const { ordinalAddress, btcAddress, btcAddressPubKey, network, wallet } = data;
            if (ordinalAddress && btcAddress && btcAddressPubKey && network) {
                setWalletInfo({ ordinalAddress, btcAddress, btcAddressPubKey, network, wallet });
                setIsConnected(true);
            } else {
                throw new Error('Failed to get wallet addresses from server');
            }
        },
        onError: (error: Error) => {
            clearWalletInfo();
            console.error('Authentication check failed:', error.message);
            toast.error('Authentication check failed: ' + error.message);
        },
    });
};

export const useConnectWalletMutation = () => {
    const setWalletInfo = useAccountStore(state => state.setWalletInfo);
    const setIsConnected = useAccountStore(state => state.setIsConnected);
    const setJwt = useJwtStore(state => state.setJwt);

    return useMutation({
        mutationFn: async (vars: { network: Network, wallet: Wallet }) => {
            const network = vars.network;
            const wallet = vars.wallet;
            try {
                if (wallet === "leather") {
                    if (!window.LeatherProvider) {
                        throw new Error('Leather not found. Check if the extension is installed.');
                    }

                    const response = await window.LeatherProvider.request("getAddresses")
                    if (!response || 'error' in response) {
                        const error = response?.error as { message: string } | undefined;
                        throw new Error(error?.message ?? 'Request failed');
                    }

                    const btcAddress = response?.result.addresses.find((address: any) => address.type === 'p2wpkh')?.address;
                    const btcAddressPubKey = response?.result.addresses.find((address: any) => address.type === 'p2wpkh')?.publicKey;
                    const ordinalAddress = response?.result.addresses.find((address: any) => address.type === 'p2tr')?.address;

                    if (!ordinalAddress || !btcAddress) {
                        throw new Error('Failed to retrieve wallet addresses');
                    }

                    if (!btcAddressPubKey) {
                        throw new Error('Failed to retrieve wallet public key');
                    }

                    if (!makeNetworkAddressSchema(network).safeParse(ordinalAddress).success) {
                        throw new Error(`Ordinal address does not match ${network} network. Please check your wallet settings.`);
                    }

                    const messageResponse = await fetch('/api/account/connect', {
                        headers: { 'Content-Type': 'application/json' }
                    });

                    if (!messageResponse.ok) {
                        throw new Error('Failed to get message from server');
                    }

                    const { message } = await messageResponse.json() as { message: string };

                    const signResponse = await window.LeatherProvider.request("signMessage", {
                        message: message,
                        paymentType: "p2tr",
                        network: network,
                    });

                    if (!signResponse || "error" in signResponse) {
                        throw new Error('Failed to sign message');
                    }

                    const parsed = AccountConnectRequestSchema.safeParse({
                        ordinal_address: ordinalAddress,
                        btc_address: btcAddress,
                        btc_address_pub_key: btcAddressPubKey,
                        wallet: 'leather',
                        message,
                        signature: signResponse.result.signature,
                    });

                    if (!parsed.success) {
                        throw new Error('Failed to parse connect body');
                    }

                    const registerResponse = await fetch('/api/account/connect', {
                        method: 'POST',
                        body: JSON.stringify(parsed.data),
                        credentials: 'include',
                    });

                    if (!registerResponse.ok) {
                        throw new Error('Failed to register wallet with server');
                    }

                    const result = await registerResponse.json();
                    const { jwt } = JwtResponseSchema.parse(result);

                    return { jwt, ordinalAddress, btcAddress, btcAddressPubKey, wallet: 'leather' as Wallet };


                } else if (wallet === "xverse") {
                    const response = await walletRequest('getAccounts', {
                        purposes: [AddressPurpose.Ordinals, AddressPurpose.Payment],
                    })

                    if (response.status !== 'success') {
                        throw new Error('Failed to connect wallet');
                    }

                    const accounts = response.result as GetAccountsResult;

                    const ordinal = accounts.find(
                        (address) => address.purpose === AddressPurpose.Ordinals
                    );

                    const btc = accounts.find(
                        (address) => address.purpose === AddressPurpose.Payment
                    );

                    const ordinalAddress = ordinal?.address || '';
                    const btcAddress = btc?.address || '';
                    const btcAddressPubKey = btc?.publicKey || '';

                    if (!ordinalAddress || !btcAddress) {
                        throw new Error('Failed to retrieve wallet addresses');
                    }

                    if (!btcAddressPubKey) {
                        throw new Error('Failed to retrieve wallet public key');
                    }

                    if (!makeNetworkAddressSchema(network).safeParse(ordinalAddress).success) {
                        throw new Error(`Ordinal address does not match ${network} network. Please check your wallet settings.`);
                    }

                    const messageResponse = await fetch('/api/account/connect', {
                        headers: { 'Content-Type': 'application/json' }
                    });

                    if (!messageResponse.ok) {
                        throw new Error('Failed to get message from server');
                    }

                    const { message } = await messageResponse.json() as { message: string };

                    const signResponse = await walletRequest("signMessage", {
                        address: ordinalAddress,
                        message: message,
                    });

                    if (!signResponse || signResponse.status !== "success") {
                        throw new Error('Failed to sign message');
                    }

                    const parsed = AccountConnectRequestSchema.safeParse({
                        ordinal_address: ordinalAddress,
                        btc_address: btcAddress,
                        btc_address_pub_key: btcAddressPubKey,
                        wallet: 'xverse',
                        message,
                        signature: signResponse.result.signature,
                    });

                    if (!parsed.success) {
                        throw new Error('Failed to parse connect body');
                    }

                    const registerResponse = await fetch('/api/account/connect', {
                        method: 'POST',
                        body: JSON.stringify(parsed.data),
                        credentials: 'include',
                    });

                    if (!registerResponse.ok) {
                        throw new Error('Failed to register wallet with server');
                    }

                    const result = await registerResponse.json();
                    const { jwt } = JwtResponseSchema.parse(result);

                    return { jwt, ordinalAddress, btcAddress, btcAddressPubKey, wallet: 'xverse' as Wallet };
                }

                throw new Error('Wallet type must be leather or xverse');
            } catch (error: any) {
                throw new Error('Failed to connect wallet: ' + error.message);
            }
        },
        onSuccess: (data, vars) => {
            const { jwt, ordinalAddress, btcAddress, btcAddressPubKey, wallet } = data;
            const network = vars.network;
            setJwt(jwt);
            setWalletInfo({ ordinalAddress, btcAddress, btcAddressPubKey, network, wallet });
            setIsConnected(true);
        },
        onError: (error: Error) => {
            console.error('Error connecting wallet:', error);
        },
    });
};

export const useDisconnectWalletMutation = () => {
    const clearWalletInfo = useAccountStore(state => state.clearWalletInfo);

    return useMutation({
        mutationFn: async () => {
            const response = await fetch('/api/account/logout', {
                method: 'POST',
                credentials: 'include',
            });
            if (!response.ok) {
                throw new Error('Failed to disconnect wallet');
            }
        },
        onSuccess: () => {
            clearWalletInfo();
        },
        onError: (error: Error) => {
            console.error('Error disconnecting wallet:', error);
        },
    });
};

export const useSetEmailMutation = () => {
    const jwt = useJwtStore(state => state.jwt);
    const network = useAccountStore(state => state.network) as Network;

    return useMutation({
        mutationFn: async (email: string | null) => {
            const response = await fetch(
                agoraUrl(['account', 'email'], network),
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${jwt}`,
                    },
                    body: JSON.stringify(EmailRegistrationSchema.parse({ email })),
                    credentials: 'include',
                });

            if (!response.ok) {
                throw new Error('Failed to set email');
            }
        },
        onError: (error: Error) => {
            console.error('Error setting email:', error);
        },
    });
};


export const usePlaceBidMutation = () => {
    const { ordinalAddress, btcAddress, btcAddressPubKey, wallet } = useAccountStore();
    const { data: currentAuction } = useCurrentAuctionQuery();
    const network = useAccountStore(state => state.network) as Network;
    const jwt = useJwtStore(state => state.jwt);

    return useMutation({
        mutationFn: async ({ amount, feeRate, kitDate }: { kitDate: KitDate, amount: number; feeRate: number }) => {
            if (!ordinalAddress || !btcAddress || !btcAddressPubKey) {
                throw new Error('Wallet not connected');
            }

            if (!currentAuction) {
                throw new Error('No current auction');
            }

            const createBidBody = {
                kit_date: kitDate,
                auction_cycle: currentAuction.auction_cycle,
                amount,
                ordinal_address: ordinalAddress,
                btc_address: btcAddress,
                btc_address_pub_key: btcAddressPubKey,
                fee_rate: feeRate,
                bidding_address: currentAuction.bidding_address
            };

            const parsed = CreateBidSchema.safeParse(createBidBody);
            if (!parsed.success) {
                throw new Error(`Invalid bid create request data: ${JSON.stringify(parsed.error.errors)}`);
            }

            // Step 1: Create bid
            const createResponse = await fetch('/api/account/bid/create', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(parsed.data),
            });

            if (!createResponse.ok) {
                throw new Error('Failed to create bid');
            }

            const bidCreate: CreateBidResponse = await createResponse.json();


            const psbt = await (async () => {
                if (wallet === 'leather') {

                    if (!window.LeatherProvider) {
                        throw new Error('Leather not found. Check if the extension is installed.');
                    }

                    const signResponse = await window.LeatherProvider.request("signPsbt", {
                        hex: base64ToHex(bidCreate.psbt),
                        signAtIndex: bidCreate.sign_inputs,
                        broadcast: false,
                    });

                    if (!signResponse || 'error' in signResponse) {
                        throw new Error('Failed to sign PSBT');
                    }

                    return hexToBase64(signResponse.result.hex);

                } else if (wallet === 'xverse') {

                    const signResponse = await walletRequest('signPsbt', {
                        psbt: bidCreate.psbt,
                        allowedSignHash: bidCreate.sighash,
                        signInputs: {
                            [bidCreate.btc_address]: bidCreate.sign_inputs,
                        },
                        broadcast: false,
                    }) as { result: { psbt: string } };


                    if (!signResponse.result.psbt) {
                        throw new Error('Failed to sign PSBT');
                    }

                    return signResponse.result.psbt;
                } else {
                    throw new Error('Wallet type must be leather or xverse');
                }
            })();



            // Step 3: Submit bid
            const submitBody = {
                ...bidCreate,
                psbt: psbt
            };

            const parsedSubmit = AccountBidSchema.safeParse(submitBody);
            if (!parsedSubmit.success) {
                throw new Error(`Invalid account bid data: ${JSON.stringify(parsedSubmit.error.errors)}`);
            }

            const submitResponse = await fetch(
                agoraUrl(['account', 'bid'], network),
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${jwt}`,
                    },
                    body: JSON.stringify(parsedSubmit.data),
                    credentials: 'include',
                }
            )


            if (!submitResponse.ok) {
                throw new Error(`HTTP error! status: ${submitResponse.status}`);
            }
            const data = await submitResponse.json() as RankedBid;
            return data;
        },
        onError: (error: Error) => {
            console.error('Error placing bid:', error);
        },
    });
};

export const useAccountUtxoQuery = (
    options: Omit<UseQueryOptions<Utxo[], Error>, 'queryKey' | 'queryFn'> = {}
) => {
    const isConnected = useAccountStore(state => state.isConnected);
    const network = useAccountStore(state => state.network) as Network;
    const btcAddress = useAccountStore(state => state.btcAddress);
    return useQuery<Utxo[], Error>({
        queryKey: ['account', 'utxos'],
        queryFn: async () => {
            const res = await clientFetchAndValidate(
                agoraUrl(['utxos', btcAddress], network),
                UtxoSchema.array(),
                {
                    headers: { 'Content-Type': 'application/json' },
                    credentials: 'include'
                },
            );
            return res;
        },
        enabled: isConnected === true && btcAddress !== '',
        ...options,
    });
}

export const useAccountStatusQuery = (
    options: Omit<UseQueryOptions<AccountInfo, Error>, 'queryKey' | 'queryFn'> = {}
) => {
    const isConnected = useAccountStore(state => state.isConnected);
    const network = useAccountStore(state => state.network) as Network;
    const jwt = useJwtStore(state => state.jwt);

    return useQuery<AccountInfo, Error>({
        queryKey: ['account', 'status'],
        queryFn: async () => {
            const res = await clientFetchAndValidate(
                agoraUrl(['account', 'status'], network),
                AccountInfoSchema,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${jwt}`
                    },
                    credentials: 'include'
                },
            );
            return res;
        },
        enabled: isConnected === true,
        ...options,
    });
}
