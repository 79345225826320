import { z } from 'zod';
import { fromError, isValidationError } from 'zod-validation-error';
import { AddressPubKeySchema, BtcAddressSchema, BtcMessageSchema, OrdinalAddressSchema, SignatureSchema, WalletSchema } from './bitcoin';
export {
    decodeRefreshToken,
    encodeRefreshToken,
    decodeJwt,
    JwtSchema,
    RefreshTokenSchema
};

export type { Jwt, JwtResponse, RefreshToken };

const RefreshTokenSchema = z.object({
    ordinal_address: OrdinalAddressSchema,
    btc_address: BtcAddressSchema,
    btc_address_pub_key: AddressPubKeySchema,
    message: BtcMessageSchema,
    wallet: z.union([WalletSchema, z.undefined()]),
    signature: SignatureSchema,
});

const encodeRefreshToken = (data: RefreshToken) => {
    return btoa(JSON.stringify(data));
}

const decodeRefreshToken = (data: string) => {
    return RefreshTokenSchema.parse(JSON.parse(atob(data)));
}

const JwtSchema = z.object({
    ordinal_address: OrdinalAddressSchema,
    btc_address: BtcAddressSchema,
    exp: z.number(),
    roles: z.array(z.string()), 
})

const decodeJwt = (jwt: string) => {
    return JwtSchema.parse(JSON.parse(atob(jwt.split('.')[1])));
}

export const JwtResponseSchema = z.object({
    jwt: z.string(),
});

type Jwt = z.infer<typeof JwtSchema>;
type RefreshToken = z.infer<typeof RefreshTokenSchema>;
type JwtResponse = z.infer<typeof JwtResponseSchema>;

export const getRefreshToken = (request: Request) => {
    const cookies = request.headers.get("Cookie");
    const refreshTokenEncoded = cookies?.split("; ").find((cookie) => cookie.startsWith("refreshToken="))?.split("=")[1];
    if (!refreshTokenEncoded) {
        throw new Error("No refresh token found");
    }
    try {
        const decoded = JSON.parse(atob(refreshTokenEncoded));
        const parsed = RefreshTokenSchema.parse(decoded);
        return { refreshToken: parsed, refreshTokenEncoded };
    } catch (error) {
        if (isValidationError(error)) {
            console.error("Validation error in account refresh action:", fromError(error).toString());
            throw new Error("Failed to decode refresh token");
        }
        throw new Error("Failed to decode refresh token");
    }
}
